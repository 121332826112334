
import React, {useEffect} from "react"
import Layout from "../../components/layout/Layout"
import { useQuery } from "@apollo/client";
import { ARTICLE } from "../../query/articles";
import { Helmet } from "react-helmet";
import { format } from 'date-fns'
import { SRLWrapper } from "simple-react-lightbox";
import { Loading } from "../../components/layout/Loading";

export const MediaDetail = ({location}) => {

    let url = null;
    let path
    path = location.pathname.split('/');
    if(path[1]==='media') {
        url = path[2];
    }  

    let title = 'Exekutorský úřad Praha 9';
    let queryName = ARTICLE;
    const {loading, data} = useQuery(queryName, {
        variables: {
            url: url
        }
    });

    let item = data?.articleFindOneByUrl || null; 
    let datum

    
    item?.issueDate && (datum = format(new Date(item.issueDate),'dd. MM. yyyy'))
    item?.name && (title = item.name + '| Exekutorský úřad Praha 9');
    

     

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title> 
        </Helmet>
        <Layout location={location} pageContext={{title: item?.name }}> 
        {(loading || !item) ? 
            <Loading loading={loading} />
        :
    
         <div>   
                
                <section className="max-w-6xl mx-auto mb-5" >
                
                        
                  <div className="rounded-lg bg-white overflow-hidden  "> 
                    <div className=" px-10 ">

                        <div className="lg:grid lg:grid-cols-3 gap-6">
                            
                             <div className="col-span-2">
                                    <div className="flex justify-end py-3 prose">
                                        <span className="text-gray-500 text-sm">{datum}</span> 
                                    </div>
                                <div dangerouslySetInnerHTML={{__html: item.prologue}} className=" prose p-5 bg-gray-100 rounded-md mb-10">
                                
                                </div>
                                {item.text && 
                                <>
                                     
                                    <div dangerouslySetInnerHTML={{__html: item.text}} className=" prose">
                                    
                                    </div>
                                </>
                                }

                                    
                                    
                             </div>
                             <div className="flex-shrink-0 max-w-sm mx-auto mt-10 mb-10 ">
                             <SRLWrapper>
                                <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 mx-auto">
                                    {item?.files?.length > 0 && item?.files?.map((e,index) => {
                                        if(e.type === 'img') 
                                        return (
                                            <div key={index} className={"overflow-hidden " + (index===0 ? "col-span-2 lg:col-span-3" : "h-32 ")}>
                                        <img
                                            className="cursor-pointer object-cover min-h-full"
                                            src={e.filename}
                                            alt={item?.nazev}
                                        />
                                        </div>
                                    )
                                })}
                                </div>
                            </SRLWrapper>
                              
                            </div>
                        </div>
                    </div> 
                  </div>
                </section>
            </div>
            }
        </Layout>
        </>
    )
}