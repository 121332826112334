
import React from "react" 
import { Helmet } from "react-helmet";      
import { Router } from "@reach/router"
import { ApolloProvider } from "@apollo/client";
import { apolloClient  } from "../client/apollo";
import { MediaDetail } from "../templates/media/MediaDetail";
 
const MediaDetailPage = ({ location}) => {

  return (
      <>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Média a zprávy | Exekutorský úřad Praha 9</title> 
        </Helmet>  

        <ApolloProvider client={apolloClient}>
            <Router> 
                <MediaDetail path="/media/:alias" location={location} />  
            </Router>
          </ApolloProvider>
      </>

  )
}
 
export default MediaDetailPage
 